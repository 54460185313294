@use '@angular/material' as mat;
@import "theme_vars";
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "styles/calendar";
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$custom-typography: mat.m2-define-typography-config(
  //$font-family: 'Roboto,Helvetica Neue,sans-serif',
  $font-family: 'sans-serif',
  $headline-1: mat.m2-define-typography-level(32px, 48px, 700),
  $body-1: mat.m2-define-typography-level(12px, 14px, 500),
  $body-2: mat.m2-define-typography-level(12px, 14px, 500),
);

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-mdc-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
/*@include mat.all-component-typographies($custom-typography);
@include mat.core();*/

/*$app-primary: mat.m2-define-palette(mat.$m2-red-palette, 900);
$app-accent: mat.m2-define-palette(mat.$m2-red-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette(mat.$m2-red-palette);*/

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$red-palette,
    /*accent: $app-accent,
    warn: $app-warn,*/
  ),
  typography: (
    plain-family: 'sans-serif',
    brand-family: 'sans-serif',
    regular-weight: 100,
    medium-weight: 500,
    bold-weight: 900,
  ),
  density: (
    scale: 0
  )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($app-theme);

/* You can add global styles to this file, and also import other style files */

/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/

:root {
  --app-height: 100vh;
  --app-width: 100vw;
  --app-section-title-height: 0px;
  --app-navbar-height: 46px;
  --app-footer-height: 25px;
  --app-content-height: calc(var(--app-height) - var(--app-navbar-height) - var(--app-footer-height));
  --app-full-content-height: calc(var(--app-height) - var(--app-footer-height));

}

html {
  @include mat.core();
  @include mat.core-theme($app-theme);
  @include mat.all-component-themes($app-theme);
}
@import "styles/jgm-mat-theme";

.mat-badge.badgeOk {
  .mat-badge-content {
   background-color: darkgreen;
  }
}

.mat-badge.badgeDanger {
  .mat-badge-content {
    background-color: darkred;
  }
}

.mat-badge.badgeWarn {
  .mat-badge-content {
    background-color: orange;
  }
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  color: #444 !important;
  /*font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;*/
  font-family: sans-serif;
  font-size: 1rem;
  background-color: #ecf0f5;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 40px !important;
}

mat-mdc-form-field.bordered {
  input {
    border: 1px solid lightgrey;
  }
}

mat-toolbar {
  background-color: $toolbar_bg_color !important;
  color: $toolbar_font_color !important;
}

mat-toolbar.ticketnavi {
  background-color: #3b5358 !important;
}

span.fill-remaining-space {
  flex: 1 1 auto;
}

button.buttonstyle2 {
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.12);
  height: 46px !important;

  .mat-mdc-button-wrapper {
    svg-icon {
      top: -3px;
      position: relative;
    }
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.nomarginbottom.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  margin-bottom: 0;
}

.nopaddingbottom.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-wrapper {
  padding-bottom: 0;
}

button.buttonstylesuffix {
  border: solid 1px rgba(0, 0, 0, 0.12);
  height: 51px;
  position: relative;
  top: -8px;
  margin-right: -9px;
}

div.inlineloader {
  display: inline-block;
  margin-bottom: -3px;
}

div.paddedcontent {
  padding: 5px;
}

div.paddedcontentlr {
  padding: 0 5px;
}

div.paddedcontent-2 {
  padding: 10px;
}

div.paddedcontent-3 {
  padding: 15px;
}

div.paddedcontent-4 {
  padding: 20px;
}

div.paddedcontent.nopaddingbottom {
  padding-bottom: 0;
}

.whitespinner .mat-mdc-progress-spinner circle, .whitespinner .mat-mdc-spinner circle {
  stroke: white;
}

.popoverbutton {
  cursor: pointer;
}

table mat-mdc-checkbox label {
  margin-bottom: 0px;
  margin-top: -2px;
}

table .mat-mdc-checkbox-inner-container {
  width: 14px !important;
  height: 14px !important;
}


div.emailcontent img {
  max-width: 100%;
  height: auto;
}

.titlebox {
  padding: 5px;
}

.underlined {
  display: inline-block;
  padding-right: 5px;
  border-bottom: 1px solid $bar_bg_color;
}

.center {
  text-align: center;
}

input.mat-mdc-input-element, mat-mdc-select {
  margin: 1px;
}

td.one-percent {
  width: 1%;
  white-space: nowrap;
}

div.searchbox {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  color: white !important;
}

div.searchbox_bottom {
  margin-top: 5px;
}

div.searchbox mat-mdc-select {
  color: white !important;
}

div.searchbox label {
  color: white !important;
}

div.searchbox .mat-mdc-select-value-text {
  color: white !important;
}

div.searchbox .mat-mdc-form-field-underline {
  background-color: white !important;
}

div.searchbox .mat-mdc-form-field-underline span {
  background-color: white !important;
}

div.tablefilter {
  padding: 5px;
}

@media screen and (max-width: 1100px) {
  div.sectiontitle {
    mat-toolbar {
      span {
        font-size: 1.7vw;
        white-space: normal;
        line-height: 10px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  div.sectiontitle {
    mat-toolbar {
      span {
        font-size: 2vw;
        white-space: normal;
        line-height: 10px;
      }
    }
  }
}

.sectiontitle {
}

.sectiontitle.withmenu {
}

.swiperleft {
  vertical-align: top;
  display: inline-block;
  width: 5px;
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
}

.swiperright {
  vertical-align: top;
  display: inline-block;
  width: 5px;
  height: calc(100% - 40px);
  max-height: calc(100% - 40px);
}

.sectioncontent {
  line-height: 22px;
  vertical-align: top;
  overflow: hidden;
  height: $content_height;
}

.appmain {
  height: var(--app-height);
  position: relative;
  overflow: hidden;
}

.logincontent {
  max-width: 100vw;
  vertical-align: top;
  overflow: hidden;
  height: $full_content_height;

  margin-bottom: 25px;
}

.footer {
  border-top: 1px solid lightgrey;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 5px;
  height: 25px;
  background-color: white;
  text-align: right;

  td {
    padding-left: 20px;
  }
}

mat-mdc-checkbox.margin {
  margin: 5px;
}

mat-mdc-table.clickable {
  mat-mdc-row:hover {
    cursor: pointer;

    mat-mdc-cell {
      background-color: #ecf0f5;
    }
  }
}

.row-title {
  padding: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #222d32;
  white-space: nowrap;

  mat-mdc-icon {
    margin-right: 5px;
  }
}

.box {
  background-color: transparent;

  .box-title {
    padding: 15px;
    font-size: 1.5rem;
    font-weight: bold;

    mat-mdc-icon {
      margin-right: 5px;
    }
  }
  .title {
    padding: 5px 15px;
    background-color: $bar_bg_color;
    color: $font_color_3;

    h2 {
      margin-bottom: 0;
    }
  }

  .header span {
    font-weight: bold;
  }

  .box-body {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.box.background {
  .box-content {
    margin: 5px;
    padding: 15px;
    background-color: white;
  }
}

.box.striped {
  .box-row {
    border-top: 1px solid #dddddd;
    border-collapse: collapse;

    .cell {
      padding: 2px;
    }
  }

  .box-row:nth-child(2n) {
    background-color: #f5f5f5;
  }
}

.ngxmatselect {
  position: relative;
  top: 50px;
}

.hidden {
  display: none !important;
}

.full-width {
  width: 100%;
}


nav.enhanced {
  .mat-mdc-tab-label-container {
    .mat-mdc-tab-label {
      opacity: 1;
      padding: 0;
    }
  }

  > .mat-mdc-tab-links > .mat-mdc-ink-bar {
    display: none;
  }
}

div.filelist {
  div.drop-zone {
    margin: 0;
    border: none;
    border-radius: 0;
    height: 100%;

    div.content {
      color: black;
      height: 100%;
      justify-content: normal;
      align-items: normal;


      div.table {
        width: calc(100% - 15px);

        .row.clickable {
          cursor: pointer;
        }

        .row.clickable:hover {
          background-color: lightyellow;
        }

        .row.header {
          border-bottom: 1px solid #b8c7ce;
          margin-bottom: 10px;

          .col {
            font-weight: bold;
          }
        }

        .row {
          .col {
            display: inline-block;
            width: auto;
          }

          .col1 {
            flex-grow: 2;
          }
        }


      }
    }
  }

}

.lkpanel {
  mat-mdc-option {
    height: 100px !important;

    span.mat-mdc-option-text {
      line-height: 14px;
    }
  }
}

@media screen and (max-width: 960px) {

  .mat-mdc-table.transpose-sm {

    border: 0;
    vertical-align: middle;

    caption {
      font-size: 1em;
    }

    /*  Enable this to hide header
    .mat-mdc-table .mat-mdc-header-cell {

      border: 10px solid;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    */

    .mat-mdc-row {
      border-bottom: 10px solid #ddd;
      display: block;
    }

    .mat-mdc-cell {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 1em;
      text-align: right;
      font-weight: bold;
      height: 30px;
      margin-bottom: 4%;
      padding-right: 15px;
    }

    .mat-mdc-cell:before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: normal;
      font-size: .85em;
      padding-left: 15px;
    }

    .mat-mdc-cell:last-child {
      border-bottom: 0;
    }

    .mat-mdc-cell:first-child {
      margin-top: 4%;
      padding-left: 0px;
    }
  }
}

input:-webkit-autofill {
  background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}


/** APP HEADER **/

mat-toolbar.app-header {
  background-color: white !important;
  color: #333 !important;

  .color_orange {
    color: orange !important;
  }

  button {
    color: currentColor;
  }

  h1 {
    color: currentColor;
  }

  .logo {
    display: inline-block;
    width: 120px;
    margin-right: 10px;
    margin-left: 10px;

    img {
      /*width: 120px;
      margin-top: -10px;*/
      max-width: 120px;
      max-height: 40px;
    }
  }
}


/** MAINMENU **/
mat-mdc-nav-list.mainmenu {
  mat-mdc-divider {
    left: 0;
  }

  h4, mat-mdc-icon {
    color: #b8c7ce !important;
  }

  mat-mdc-list-item.active-link {
    border-left: 5px solid white;

    .mat-mdc-list-item-content {
      margin-left: -5px;
    }
  }
}

mat-mdc-nav-list.notesmenu {
  mat-mdc-divider {
    left: 0;
  }

  h4, mat-mdc-icon {
    color: #b8c7ce !important;
  }

  mat-mdc-list-item.active-link {
    border-right: 5px solid #b11623;

    .mat-mdc-list-item-content {
      font-weight: bold;
    }
  }

  mat-mdc-list-item.edititem {
    height: 130px;
  }
}

/** ADMINMENU **/
mat-mdc-nav-list.adminmenu {
  mat-mdc-divider {
    left: 0;
  }

  h4, mat-mdc-icon {
    color: #b8c7ce !important;
  }

  mat-mdc-list-item.active-link {
    border-left: 5px solid white;

    .mat-mdc-list-item-content {
      margin-left: -5px;
    }
  }
}

mat-mdc-nav-list.adminmenu.indashboard {
  mat-mdc-list-item {
    display: inline-block;
    width: 200px;
    height: 200px;
    text-align: center;

    border: 1px solid #b8c7ce;

    > div.mat-mdc-list-item-content {
      justify-content: center;

      mat-mdc-icon {
        height: 100px;
        width: 100px;
        font-size: 100px;
      }
    }

  }
}


// NEWSENTRYBOX
.newsboxentry {
  margin-bottom: 50px;

  div.label {
    font-weight: bold;
    font-size: 20px;
    padding: 5px;
  }

  div.content {
    background-color: $striped_bg_color;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;

    h1 {
      font-size: 16px;
      margin-bottom: 0;
    }

    ul {
      margin-top: 0;
    }

  }
}

mat-mdc-chip.active {
  border: 1px solid black;
}


.printhidden {
  display: none !important;
}

mwl-calendar-month-cell,
mwl-calendar-day-view-hour-segment,
mwl-calendar-week-view-hour-segment {
  touch-action: auto !important;
}

.ticket_editor_quill {
  .ql-editor, .ql-blank {
    min-height: 300px;
  }
}

.tutorial_skip_button {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.mobileboxcalendar {
  .cal-month-view {
    max-width: calc(100vw - 10px);


    .cal-day-cell {
      .cal-day-number {
        font-size: 8px !important
      }
    }

    .cal-day-cell .cal-today {
      .cal-day-number {
        font-size: 8px !important;
      }
    }

  }

  font-size: 9px;
}

.mat-mdc-tooltip {
  white-space: pre-line;
}


@import "styles/menu";
@import "styles/tables";
@import "styles/svg-icon";
@import "styles/dashboard";
@import "styles/search-box";
@import "styles/mat-forms";

.shop-view-filter {
  .mat-mdc-checkbox .mat-mdc-ripple-element {
    background-color: transparent;
  }
}

.mat-mdc-icon-button:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.mat-mdc-icon-button {
  padding: 0 !important;
}

.table-bg {
  background-color: white;
}

.xs-icon-button {
  height: 10px !important;
  width: 10px !important;
  line-height: 10px !important;

  mat-mdc-icon {
    line-height: 10px !important;
    height: 10px !important;
    width: 10px !important;
    font-size: 10px;
  }
}


.warenkorb-menu-cart-button {
  margin-right: 10px !important;

  .mat-mdc-badge-content {
    top: 0 !important;
    right: 3px !important;
  }
}

div.icobox {
  display: inline-block;
  width: 24px;
  height: 24px;
}


.section_title_button_highlight_green {
  mat-mdc-icon {
    color: green;
  }
}

.color_red {
  color: red;
}

.color_orange {
  color: orange;
}

.color_green {
  color: green;
}

.color_blue {
  color: #2323d2;
}

.color_lightgreen {
  color: lightgreen;
}


.infobox {
  padding: 10px;
  text-align: center;
}

.infobox.success {
  background-color: lightgreen;
}

.infobox.warn {
  background-color: #ffa487;
}

a {
  color: #017CB6;
}

a, a:active, a:link, a:visited {
  text-decoration: none;
  color: #0066c0;
}

a:hover {
  color: $hover_font_color;
}

.mat-mdc-dialog-container {
  border-radius: 0 !important;
}

label.mat-mdc-checkbox-layout {
  margin-bottom: 0;
}

.height51px {
  .mat-mdc-form-field-outline {
    height: 51px;
    margin-top: -3px;
  }
}


.togglebutton_in_form {
  height: 38px;

  .mat-mdc-button-toggle-label-content {
    line-height: 38px;
  }
}

a .field_bezeichnung.sgarticle {
  color: red;
}

a .field_bezeichnung.sgarticle:hover {
  color: #790000;
}

mat-mdc-form-field.mat-mdc-prefix-textarea {
  .mat-mdc-form-field-prefix {
    top: -.5em;
  }
}

.mat-mdc-checkbox.bordered {
  .mdc-checkbox__background {
    border-color: #ff5252 !important;
  }
}

@import "styles/globalhelpbutton";

.sectioncontent {
  .simplebar-content {
    .pagecontent {
      margin-bottom: 25px !important;
    }
  }
}

table th {
  position: sticky;
  top: 0;
  z-index: 10;
}


button.bgprimary {
  background-color: #b71c1c !important;
  color: white !important;
}

button.bgsecondary {
  background-color: #6f4a00 !important;
  color: white !important;
}

.flex025 {
  flex: 0.25;
}

.flex05 {
  flex: 0.5;
}

.mat-mdc-drawer-container {
  background-color: transparent;
}

.pdfviewerdiv {
  overflow: hidden;
}

button .svg-icon {
  margin-top: -3px;
}

.font-size-1 {
  font-size: 0.8rem;
}

.font-size-2 {
  font-size: 0.9rem;
}

.font-size-3 {
  font-size: 1rem;
}

.font-size-4 {
  font-size: 1.1rem;
}

.font-size-5 {
  font-size: 1.2rem;
}

.font-size-6 {
  font-size: 1.3rem;
}

.font-size-7 {
  font-size: 1.4rem;
}

.font-size-8 {
  font-size: 1.5rem;
}

.font-size-9 {
  font-size: 1.6rem;
}

@import "styles/global-search-box";
@import "styles/warenkorb-button";

label {
  margin-bottom: 0;
}
